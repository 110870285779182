import { useRef, useEffect } from "react"
import { useViewportScroll, useMotionValue } from "framer-motion"

const useElementScroll = () => {
  const ref = useRef()

  const { scrollY } = useViewportScroll()

  const scroll = useMotionValue(0)

  const progress = useMotionValue(0)

  const percentage = useMotionValue("0%")

  useEffect(() => {
    if (ref.current) {
      return scrollY.onChange(() => {
        const { top, height } = ref.current.getBoundingClientRect()

        const scrollValue = window.innerHeight - top

        scroll.set(scrollValue)

        const progressValue = Math.max(
          0,
          Math.min((window.innerHeight - top) / height, 1)
        )

        progress.set(progressValue)

        percentage.set(`${progressValue * 100}%`)
      })
    }
  }, [scrollY, scroll, progress, percentage])

  return [ref, { scroll, progress, percentage }]
}

export default useElementScroll
