import React from "react"
import { useLayoutContext } from "~layout/contexts/layout"
import { Box } from "~common/components/Primitives"

const ProgressBar = ({ percentage }) => {
  const { headerHeight } = useLayoutContext()
  return (
    <Box
      sx={{
        position: "sticky",
        top: headerHeight,
        zIndex: 100,
        height: 2,
      }}
    >
      <Box
        framer
        style={{ width: percentage }}
        sx={{ position: "absolute", left: 0, height: 2, bg: "accent" }}
      />
    </Box>
  )
}

export default ProgressBar
