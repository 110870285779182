import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import useSiteMetadata from "~common/hooks/useSiteMetadata"
import useElementScroll from "~common/hooks/useElementScroll"
import { useLayoutContext } from "~layout/contexts/layout"
import Page from "~common/components/Page"
import { Box } from "~common/components/Primitives"
import Typography from "~common/components/Typography"
import ProgressBar from "~blog/components/ProgressBar"

const Post = ({ data }) => {
  const { mdx: post } = data

  const { frontmatter, body, timeToRead } = post
  const { title, date, author, featuredImage, images } = frontmatter

  const { headerHeight } = useLayoutContext()

  const [bodyRef, { percentage }] = useElementScroll()

  const { siteUrl } = useSiteMetadata()

  return (
    <Page
      seo={{
        title,
        image: `${siteUrl}${featuredImage.childImageSharp.original.src}`,
      }}
    >
      <Page.Body>
        <Box
          as="article"
          sx={{
            minHeight: "100vh",
            pt: headerHeight,
            pb: [4, 5, 6],
            mx: "auto",
          }}
        >
          <GatsbyImage image={getImage(featuredImage)} alt={title} />
          <ProgressBar percentage={percentage} />
          <Box
            sx={{
              mx: [4, 5, "auto"],
              my: [4, 5],
              maxWidth: [640, 680, 720, 780],
            }}
          >
            <Link to="/blog">
              <Typography
                as="span"
                variant="small"
                sx={{
                  color: "softened-secondary",
                  "&:hover": {
                    color: "softened-accent",
                  },
                }}
              >
                &#8604; Blog
              </Typography>
            </Link>
            <Typography
              as="h1"
              variant="heading2"
              sx={{ color: "accent", mt: [4, 5] }}
            >
              {title}
            </Typography>
            <Typography
              as="span"
              variant="capitalized3"
              sx={{ color: "softened-secondary" }}
            >
              {date}
            </Typography>
            <Typography as="span" variant="small" sx={{ mx: [3, 4] }}>
              |
            </Typography>
            <Typography
              as="span"
              variant="heading4"
              sx={{ color: "secondary" }}
            >
              {author}
            </Typography>
            <Typography variant="normal">{timeToRead} minutes read</Typography>
          </Box>
          <Box ref={bodyRef} sx={{ mx: "auto", my: [4, 5] }}>
            <MDXRenderer images={images}>{body}</MDXRenderer>
          </Box>
        </Box>
      </Page.Body>
    </Page>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      timeToRead
      wordCount {
        paragraphs
        sentences
        words
      }
      frontmatter {
        path
        title
        date(formatString: "MMMM DD, YYYY")
        author
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            original {
              src
            }
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default Post
